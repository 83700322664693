/**
 * Make param for ajax
 *
 * @param a
 * @param traditional
 */
jQuery.makeAjaxParam = function (a, traditional) {

  var r20 = /%20/g,
    rbracket = /\[\]$/;

  var buildParams = function (prefix, obj, traditional, add) {
    var name;

    if (jQuery.isArray(obj)) {

      // Serialize array item.
      jQuery.each(obj, function (i, v) {
        if (traditional || rbracket.test(prefix)) {

          // Treat each array item as a scalar.
          add(prefix, v);

        } else {

          // Item is non-scalar (array or object), encode its numeric index.
          buildParams(
            prefix + "[" + ( typeof v === "object" && v != null ? i : "" ) + "]",
            v,
            traditional,
            add
          );
        }
      });

    } else if (!traditional && typeof obj === "object") {

      // Serialize object item.
      for (name in obj) {
        buildParams(prefix + "[" + name + "]", obj[name], traditional, add);
      }

    } else {

      // Serialize scalar item.
      add(prefix, obj);
    }
  };

  var param = function (a, traditional) {
    var prefix,
      s = [],
      add = function (key, value) {

        // If value is a function, invoke it and return its value
        value = jQuery.isFunction(value) ? value() : ( value == null ? "" : value );
        s[s.length] = encodeURIComponent(key) + "=" + encodeURIComponent(value);
      };

    // Set traditional to true for jQuery <= 1.3.2 behavior.
    if (traditional === undefined) {
      traditional = jQuery.ajaxSettings && jQuery.ajaxSettings.traditional;
    }

    // If an array was passed in, assume that it is an array of form elements.
    if (jQuery.isArray(a) || ( a.jquery && !jQuery.isPlainObject(a) )) {

      // Serialize the form elements
      jQuery.each(a, function () {
        add(this.name, this.value);
      });

    } else {

      // If traditional, encode the "old" way (the way 1.3.2 or older
      // did it), otherwise encode params recursively.
      for (prefix in a) {
        buildParams(prefix, a[prefix], traditional, add);
      }
    }

    // Return the resulting serialization
    return s.join("&").replace(r20, "+");
  };

  return param(a, traditional);
};

/**
 * Quince
 */
var Quince = {};

/**
 * Example
 */
(function ($, window) {
  'use strict';

  /**
   * Constructor
   */
  Quince.example = function () {
    this.id = 0;
  };

  /**
   * Methods
   */
  Quince.example.prototype = {
    setId: function (id) {
      this.id = id;
    }
  };

  Quince.example.prototype.constructor = Quince.example;
})(jQuery, window);

/**
 * Quince log
 */
(function ($, window) {
  'use strict';

  /**
   * Log service
   * @type {Object}
   */
  Quince.LogService = {
    /**
     * All logs
     */
    list: [],

    /**
     * Get all logs
     */
    getAll: function () {
      return Quince.LogService.list;
    },

    /**
     * Show in console
     */
    showInConsole: function () {
      $.each(Quince.LogService.list, function (i, v) {
        console.log(i + ':' + JSON.stringify(v));
      });
    }
  };

  /**
   * Constructor
   */
  Quince.Log = function (name) {
    /**
     * Name
     * @type {String}
     */
    this.name = name || ('no-name-' + Quince.LogService.list.length);

    /**
     * Logs
     * @type {Array}
     */
    this.list = [];
  };

  /**
   * Methods
   */
  Quince.Log.prototype = {
    /**
     * Add log
     * @param {String} message
     * @param {String} code
     * @returns {Quince.Log}
     */
    add: function (message, code) {
      code = code || 'general';
      this.list.push({code: code, message: message});
      Quince.LogService.list.push({name: this.name, code: code, message: message});

      return this;
    }
  };

  Quince.Log.prototype.constructor = Quince.Log;
})(jQuery, window);


Quince = Quince || {};

/**
 * AjaxRequest
 */
(function ($, window) {
  /**
   * Request service
   * @type {Object}
   */
  Quince.AjaxRequestService = {
    ENDPOINT_SEARCH_PANEL: 'searchPanel',
    ENDPOINT_CUSTOMER_REQUEST: 'customerRequest',
    ENDPOINT_SEARCH_ESTATES: 'searchEstates',
    ENDPOINT_FAVORITES_INFO: 'favoritesInfo',
    ENDPOINT_FAVORITES_REGISTER: 'favoritesRegister',
    ENDPOINT_TRACKING_REGISTER: 'trackingRegister',
    ENDPOINT_ESTATE_NOTIFICATIONS: 'estateNotifications',
    /**
     * XHR requests
     */
    xhrRequests: [],
    /**
     * Time events
     */
    timeEvents: [],
    /**
     * Response
     */
    response: null,
    /**
     * Error
     */
    error: null,
    /**
     * Endpoints
     */
    endpoints: {
      searchPanel: {
        url: {ro: '/api/searchPanel/doSearch', en: '/en/api/searchPanel/doSearch'},
        method: 'POST',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      },
      customerRequest: {
        url: {ro: '/api/customerRequest/send', en: '/en/api/customerRequest/send'},
        method: 'POST',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      },
      searchEstates: {
        url: {ro: '/api/search/estates', en: '/en/api/search/estates'},
        method: 'POST',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      },
      favoritesInfo: {
        url: {ro: '/api/favorites/get', en: '/en/api/favorites/get'},
        method: 'GET',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      },
      favoritesRegister: {
        url: {ro: '/api/favorites/register', en: '/en/api/favorites/register'},
        method: 'POST',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      },
      trackingRegister: {
        url: {ro: '/api/pageTracking/register', en: '/api/pageTracking/register'},
        method: 'POST',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      },
      estateNotifications: {
        url: {ro: '/api/estateNotifications/show', en: '/en/api/estateNotifications/show'},
        method: 'POST',
        dataType: 'json',
        success: function (r) {
        }, error: function (r) {
        }
      }
    },
    /**
     * Build request URL
     *
     * @param  {String} url
     * @param  {Object} params
     *
     * @return {String}
     */
    buildRequestUrl: function (url, params) {
      var requestUrl = url;
      $.each(params, function (key, value) {
        requestUrl = requestUrl.replace(':' + key, value);
      });

      return requestUrl.replace(new RegExp('\/+$', 'g'), '/');
    },

    /**
     * Clear ajax requests for the same endpoint
     */
    clearEndpointXhrRequests: function (endpointName) {
      if (Quince.AjaxRequestService.xhrRequests.length) {
        $.each(Quince.AjaxRequestService.xhrRequests, function (index, jqXHR) {
          if (jqXHR.endpointName == endpointName) {
            jqXHR.request.abort();
          }
        });
      }
    },

    /**
     * Clear ajax requests
     */
    clearAllXhrRequests: function () {
      if (Quince.AjaxRequestService.xhrRequests.length) {
        $.each(Quince.AjaxRequestService.xhrRequests, function (index, jqXHR) {
          jqXHR.request.abort();
        });
      }
    },

    /**
     * Time event
     * @param callback
     * @param milliseconds
     */
    timeEvent: function (callback, milliseconds) {
      Quince.AjaxRequestService.timeEvents.push(setTimeout(callback, milliseconds));
    },

    /**
     * Clear time events
     */
    clearTimeEvents: function () {
      if (Quince.AjaxRequestService.timeEvents.length) {
        $.each(Quince.AjaxRequestService.timeEvents, function (index, ev) {
          clearTimeout(ev);
        });
      }
    }
  };

  /**
   * Constructor
   */
  Quince.AjaxRequest = function (endpoint, data, params) {
    /**
     * Poast data
     * @type {*|{}}
     */
    this.data = data || {};
    /**
     * Params
     * @type {*|*|{}}
     */
    this.params = params || this.data;
    /**
     * Endpoint
     * @type {{}}
     */
    this.endpoint = {};
    this.setEndpoint(endpoint);
  };

  /**
   * Methods
   */
  Quince.AjaxRequest.prototype = {
    /**
     * Set endpoint
     * @param name
     * @returns {Quince.AjaxRequest}
     */
    setEndpoint: function (name) {
      this.endpointName = name || '';
      this.endpoint = {};
      if (this.endpointName && typeof this.endpointName == 'string') {
        $.extend(true, this.endpoint, Quince.AjaxRequestService.endpoints[this.endpointName] || {});
      }
      if (this.endpointName && typeof this.endpointName == 'object') {
        $.extend(true, this.endpoint, this.endpointName);
      }
      if (typeof this.endpoint.url != 'undefined') {
        if (typeof this.endpoint.url != 'string') {
          this.endpoint.url = Quince.AjaxRequestService.buildRequestUrl(this.endpoint.url[__quince__.lang], this.params);
        } else {
          this.endpoint.url = Quince.AjaxRequestService.buildRequestUrl(this.endpoint.url, this.params);
        }
      }

      return this;
    },

    /**
     * Success
     * @param callback
     * @returns {Quince.AjaxRequest}
     */
    onSuccess: function (callback) {
      if (self.endpoint && self.endpoint.url) {
        this.endpoint.success = callback;
      }

      return this;
    },

    /**
     * Error
     * @param callback
     * @returns {Quince.AjaxRequest}
     */
    onError: function (callback) {
      if (self.endpoint && self.endpoint.url) {
        this.endpoint.error = callback;
      }

      return this;
    },

    /**
     * Run request
     */
    run: function (success, error) {
      var self = this;
      success = success || null;
      error = error || null;
      if (self.endpoint && self.endpoint.url) {
        self.endpoint.success = success || self.endpoint.success;
        self.endpoint.error = error || self.endpoint.error;
        self.endpoint.timeout = self.endpoint.timeout || 60000;
        var jqX = $.ajax({
          url: self.endpoint.url,
          type: self.endpoint.method,
          data: $.makeAjaxParam(self.data),
          dataType: self.endpoint.dataType || 'json',
          timeout: self.endpoint.timeout,
          async: false,
          beforeSend: function (jqXHR) {
            Quince.AjaxRequestService.xhrRequests.push({
              endpointName: self.endpointName,
              request: jqXHR
            });
          },
          success: function (response, textStatus) {
            self.response = response;
            self.endpoint.success(self.response, textStatus, self);
          },
          error: function (xhr, textStatus, thrownError) {
            self.error = xhr;
            self.endpoint.error(self.response, textStatus, self);
          }
        });
      }

      return self;
    }
  };

  Quince.AjaxRequest.prototype.constructor = Quince.AjaxRequest;
})(jQuery, window);


Quince = Quince || {};

/**
 * Cookie
 */
(function ($, window) {
  /**
   * Cookie service
   * @type {Object}
   */
  Quince.CookieService = {
    PREFIX: '',
    options: {
      path: '/'
    },

    /**
     * Set a cookie
     *
     * @param key
     * @param value
     * @param options
     */
    set: function (key, value, options) {
      key = Quince.CookieService.PREFIX + key;
      options = $.extend(Quince.CookieService.options, options);
      $.cookie(key, value, options);
    },

    /**
     * Delete a cookie
     *
     * @param key
     * @param options
     * @returns {*}
     */
    remove: function (key, options) {
      key = self.PREFIX + key;
      options = $.extend(self.options, options);

      return $.removeCookie(key, options);
    }
  };

  /**
   * Constructor
   */
  Quince.Cookie = function () {};

  /**
   * Methods
   */
  Quince.Cookie.prototype = {};
  Quince.Cookie.prototype.constructor = Quince.Cookie;
})(jQuery, window);


Quince = Quince || {};


Quince = Quince || {};

/**
 * Favorites
 */
(function ($, window) {
    'use strict';

    /**
     * Slider service
     * @type {Object}
     */
    Quince.AppService = {
        qRecalculateSizeMaxIterations: 100,

        init: function () {

        },

        onReady: function (selector, callback) {
            selector = selector || null;
            var $selector = selector ? $(selector) : $();
            $selector.ready(function () {
                callback.call();
            });
        },

        /**
         * Show elements
         */
        fadeInWidgets: function () {
            var $this = $('.q-recalculate-size'),
                $variableHeight = $this.find('.q-variable-size-height');

            $variableHeight.addClass('in');
            $this.find('.q-fixed-size-height').addClass('in');
            $this.addClass('in');
            //$('.youtube-bg, .video-bg').addClass('in');
        },

        /**
         * @returns {boolean}
         */
        qRecalculateSize: function () {
            if (Quince.AppService.qRecalculateSizeMaxIterations <= 0) {
                return false;
            }

            var $this = $('.q-recalculate-size'),
                $fixedHeight = $this.find('.q-fixed-size-height'),
                $variableHeight = $this.find('.q-variable-size-height'),
                pTop = parseInt($variableHeight.css('padding-top')),
                pBottom = parseInt($variableHeight.css('padding-bottom')),
                $pageBorder = $('.page-border.bottom:visible');

            var windowHeight = parseInt($(window).height());
            var diff = $fixedHeight.height() + $variableHeight.find('.container').height() + $pageBorder.height();

            pTop = (windowHeight - diff) / 2;
            pBottom = (windowHeight - diff) / 2;
            pBottom = pBottom - 20;

            if (pTop <= 0) {
                Quince.AppService.qRecalculateSizeMaxIterations--;
                return false;
            }

            if ($fixedHeight.find('.banner-cell').length) {
                $fixedHeight = $fixedHeight.find('.banner-cell');
            }
            if ($fixedHeight.length) {
                $variableHeight.css({
                    'padding-top': pTop,
                    'padding-bottom': pBottom
                });
            }

            Quince.AppService.qRecalculateSizeMaxIterations = 0;

            return true;
        },

        /**
         * @param delay
         */
        qRecalculateSizeDelayed: function (delay) {
            delay = delay || 2000;
            setTimeout(function () {
                var success = Quince.AppService.qRecalculateSize();
                if (!success) {
                    Quince.AppService.qRecalculateSizeDelayed(delay);
                }
            }, delay);
        },

        /**
         * Load video on background
         */
        loadVideoBg: function () {
            $('.video-bg-app').each(function () {
                $(this).addClass('video-bg');
            });
            new Quince.VideoBgApp();
        }
    };

    /**
     * Constructor
     */
    Quince.App = function () {

    };

    /**
     * Methods
     */
    Quince.App.prototype = {};
    Quince.App.prototype.constructor = Quince.App;

    $(document).ready(function () {
        $('.fade').addClass('in');
        if (!$('html').hasClass('mobile')) {
            Quince.AppService.qRecalculateSizeDelayed(200);
        }

        Quince.AppService.fadeInWidgets();
    });

    $(window).load(function () {
        setTimeout(function () {
            Quince.AppService.loadVideoBg();
        }, 1000);

        setTimeout(function () {
            $('.youtube-bg, .video-bg').addClass('in');
        }, 1100);
    });

})(jQuery, window);


(function ($, window) {
    'use strict';

    $(document).ready(function () {
        $('.dropdown').each(function (key, dropdown) {
            var $dropdown = $(dropdown);
            var $dropdownMenu = $dropdown.find('.dropdown-menu');

            $dropdown.on('show.bs.dropdown', function () {
                $('body').append($dropdownMenu.css({
                    position: 'absolute',
                    left: $dropdown.offset().left,
                    top: $dropdown.offset().top + $dropdown.height()
                }).show().detach());
            });

            $dropdown.on('hidden.bs.dropdown', function () {
                $dropdown.append($dropdownMenu.css({
                    position: false,
                    left: false,
                    top: false
                }).hide().detach());
            });

            var $selected = $dropdown.find('.dropdown-menu li.selected');
            if ($selected && $selected.text()) {
                $dropdown.find('button').text($selected.data('text')).append(' <span class="caret"></span>');
                $dropdown.find('input[type=hidden]').val($selected.data('value'));
            }
            $dropdown.find('.dropdown-menu li').on('click', function () {
                $dropdown.find('button').text($(this).data('text')).append(' <span class="caret"></span>');
                $dropdown.find('input[type=hidden]').val($(this).data('value'));
            });
        });

    });

})(jQuery, window);


Quince = Quince || {};

/**
 * GallerySlider
 */
(function ($, window) {
  'use strict';

  /**
   * Slider service
   * @type {Object}
   */
  Quince.GallerySliderService = {};

  /**
   * Constructor
   */
  Quince.GallerySlider = function (params) {
    var defaults = {
      galleryId: '#quince-slider-gallery',
      thumbsId: '#quince-slider-thumbs'
    };
    this.params = $.extend({}, defaults, params || {});
    this.galleryId = this.params.galleryId;
    this.thumbsId = this.params.thumbsId;
    this.init();
  };

  /**
   * Methods
   */
  Quince.GallerySlider.prototype = {
    /**
     * Init
     * @returns {Quince.GallerySlider}
     */
    init: function () {
      var self = this;
      self.loadGallery()
        .loadThumbs()
        .initEvents()
        .initSlides()
        .imagePosition(2);
      $(window).resize(function () {
        self.imagePosition();
      });

      return this;
    },

    /**
     * Events
     * @returns {Quince.GallerySlider}
     */
    initEvents: function () {
      var self = this;
      var slidesSum = $(".swiper-slide", $(self.galleryId)).length;
      $(self.galleryId).hover(function () {
        $('.slide-buttons', $(self.galleryId)).show();
      }, function () {
        $('.slide-buttons', $(self.galleryId)).hide();
      });
      this.gallery.on('onSlideChangeStart', function () {
        if ($(".swiper-thumbs", $(self.thumbsId)).length) {
          if (self.gallery.activeIndex == (slidesSum - 1)) {
            self.thumbs.slideTo(0);
          } else {
            self.thumbs.slideTo(self.gallery.activeIndex - 2);
          }
        }
        $(".slide-more-cont a", $(self.galleryId)).css('z-index', 10);
        $(".slide-more-cont a.num-" + self.gallery.activeIndex, $(self.galleryId)).css('z-index', 20);
      });

      return this;
    },

    /**
     * Slides
     * @returns {Quince.GallerySlider}
     */
    initSlides: function () {
      var self = this;
      $(".swiper-slide", $(self.thumbsId)).click(function () {
        self.gallery.slideTo(self.thumbs.clickedIndex + 1);
      });

      return this;
    },

    /**
     * Image position
     * @param cycle
     * @returns {Quince.GallerySlider}
     */
    imagePosition: function (cycle) {
      var self = this;
      if (cycle === undefined) {
        cycle = 0;
      }
      var imgHeight = 0,
        galleryHeight = $(self.galleryId).height();
      if (galleryHeight) {
        $(".swiper-slide img", $(self.galleryId)).each(function (i, v) {
          imgHeight = $(v).height();
          $(v).css({'margin-top': (galleryHeight - imgHeight) / 2});
        });
      } else if (cycle > 0) {
        setTimeout(function () {
          self.imagePosition(cycle - 1);
        }, 500);
      }

      return this;
    },

    /**
     * Gallery
     * @returns {Window.Swiper}
     */
    loadGallery: function () {
      var params = {
        // Optional parameters
        nextButton: '.slide-next',
        prevButton: '.slide-prev',
        loop: true,
        grabCursor: true,
        preloadImages: false,
        lazyLoading: true,
        lazyLoadingInPrevNext: true,
        loopAdditionalSlides: 0,
        autoplay: 5000,
        speed: 700,
        height: 500
      };
      this.gallery = new Swiper(this.galleryId, params);

      return this;
    },

    /**
     * Thumbs
     * @returns {Window.Swiper}
     */
    loadThumbs: function () {
      var params = {
        // Optional parameters
        nextButton: '.thumb-next',
        prevButton: '.thumb-prev',
        spaceBetween: 15,
        centeredSlides: false,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: false
      };
      this.thumbs = new Swiper(this.thumbsId, params);

      return this;
    }
  };

  Quince.GallerySlider.prototype.constructor = Quince.GallerySlider;
})(jQuery, window);


Quince = Quince || {};

/**
 * Notification
 */
(function ($, window) {
    "use strict";

    /**
     * Notification service
     * @type {Object}
     */
    Quince.NotificationService = {
        /**
         * Stop showing cookie information
         */
        cookiesAgreement: function (name) {
            var cookieValue = {'agree': 1};
            Quince.CookieService.set(name, JSON.stringify(cookieValue), {
                expires: 365 * 10,
                domain: '.' + __quince__.host
            });
            $('#q-cookies-agreement').hide();
        }
    };

    /**
     * Constructor
     */
    Quince.Notification = function (params) {
        params = params || {};
    };

    /**
     * Methods
     */
    Quince.Notification.prototype = {};

    Quince.Notification.prototype.constructor = Quince.notification;

})(jQuery, window);


var Quince = Quince || {};

/**
 * ProgramsGrid
 */
(function ($, window) {
    'use strict';
    
    /**
     * Slider service
     * @type {Object}
     */
    Quince.ProgramsGridService = {};
    
    /**
     * Constructor
     */
    Quince.ProgramsGrid = function (params) {
        
        this.resetActiveTags();
        
    };
    
    /**
     * Methods
     */
    Quince.ProgramsGrid.prototype = {
    
        activeTags: {},
        
        resetActiveTags: function () {
            this.activeTags = {};
        },
        
        intersect: function (array1, array2) {
            return $.map(array1, function (el) {
                return $.inArray(el, array2) < 0 ? null : el;
            });
        },
        
        run: function () {
            var self = this;
            
            $('.programs-grid').each(function (i) {
                var $gallery = $(this);
                var isFilter = false;
                var defaultGroup = $gallery.data('default-group') ? $gallery.data('default-group') : 'all';
                if (!defaultGroup) defaultGroup = 'all';
                var $grid = $gallery.find('.grid')
                                    .shuffle({
                                        group: defaultGroup,
                                        speed: 300,
                                        useTransforms: true
                                    })
                                    .on('filter.shuffle', function () {
                                        isFilter = true;
                                    })
                                    .on('layout.shuffle', function () {
                    
                                    })
                                    .on('filtered.shuffle', function () {
                                        if (isFilter) {
                                            isFilter = false;
                                        }
                                    });
                var $btns = $gallery.find('.filter a');
                var $items = $grid.find('.item');
                var currentGroup = defaultGroup;
                $gallery.find('.filter a[data-group=' + defaultGroup + ']').addClass('active');
                
                $btns.click(function (e) {
                    e.preventDefault();
                    if (isFilter) return;
                    var $this = $(this);
                    var isActive = $this.hasClass('active');
                    
                    var tag = $this.data('group');
                    var tagId = tag.split('.')[0];
                    var tagType = tag.split('.')[1];
                    var tagSlug = tag.split('.')[2];
                    var tagIndex = tagType + '.' + tagSlug;
                    
                    var activeTag = (self.activeTags[tagType] !== undefined && self.activeTags[tagType] === tag);
                    
                    /*if (activeTag) {
                        if (tagType === '1') {
                            self.activeTags = {};
                        } else  {
                            delete self.activeTags[tagType];
                        }
                    } else {
                        if (tagType === '1') {
                            self.activeTags = {};
                        }
    
                        self.activeTags[tagType] = tag;
                    }*/
    
                    if (activeTag) {
                        delete self.activeTags[tagType];
                    } else {
                        self.activeTags[tagType] = tag;
                    }
                    
                    $items.removeClass('show-item');
                    $items.removeClass('hide-item');
                    $btns.removeClass('active');
                    
                    $btns.each(function () {
                        var $btn = $(this);
                        var bTag = $btn.data('group');
                        var bTagId = bTag.split('.')[0];
                        var bTagType = bTag.split('.')[1];
                        var bTagSlug = bTag.split('.')[2];
                        var bTagIndex = bTagType + '.' + bTagSlug;
                        
                        if (bTag && (self.activeTags[bTagType] !== undefined && self.activeTags[bTagType] === bTag)) {
                            $btn.addClass('active');
                        }
                    });
    
                    var activeTagsCount = Object.values(self.activeTags).length;
                    
                    if (activeTagsCount === 0) {
                        $grid.shuffle('shuffle');
                        return;
                    }
                    
                    $items.each(function () {
                        var $i = $(this);
                        var itemTags = eval($i.data('groups'));
                        var itemActiveTags = 0;
                        
                        $(itemTags).each(function (tagIndex, itemTag) {
                            var tId = itemTag.split('.')[0];
                            var tType = itemTag.split('.')[1];
                            var tSlug = itemTag.split('.')[2];
                            var tIndex = tType + '.' + tSlug;
                            
                            if (activeTagsCount > 0) {
                                Object.values(self.activeTags).map(function (activeTag) {
                                    if (activeTag === itemTag) {
                                        itemActiveTags++;
                                    }
                                });
                            }
                        });
                        
                        if (activeTagsCount > 0 && activeTagsCount === itemActiveTags) {
                            $i.removeClass('hide-item').addClass('show-item');
                        } else {
                            $i.removeClass('show-item').addClass('hide-item');
                        }
                    });

                    Object.values(self.activeTags).map(function (tag) {
                        $grid.shuffle('shuffle', tag);
                    });
                    
                    var footerAnimation = $('#footer').find('.scroll-in-animation');
                    var animation = footerAnimation.data('animation') + ' animated css-animation-show';
                    footerAnimation.addClass(animation);
                });
                
                $items.click(function (e) {
                    //e.preventDefault();
                });
            });
        }
    };
    
    Quince.ProgramsGrid.prototype.constructor = Quince.ProgramsGrid;
})(jQuery, window);


Quince = Quince || {};

var ReCaptchaCallback = function () {
  Quince.ReCaptchaSrv.callback();
};

/**
 * Favorites
 */
(function ($, window) {
  'use strict';

  /**
   * Slider service
   * @type {Object}
   */
  Quince.ReCaptchaSrv = {
    publicKey: '',
    lang: '',
    captchas: [],

    /**
     * Init
     *
     * @param publicKey
     * @param lang
     * @returns {Quince.ReCaptchaSrv}
     */
    init: function (publicKey, lang) {
      this.publicKey = publicKey;
      this.lang = lang;
      this.initScript();

      return this;
    },

    /**
     * Init script
     * @returns {Quince.ReCaptchaSrv}
     */
    initScript: function () {
      (function (d, i, l) {
        var s = d.createElement('script'),
          e = d.getElementsByTagName('script')[0],
          ss = d.getElementById(i);
        if (ss) {
          return;
        }
        s.id = i;
        s.src = 'https://www.google.com/recaptcha/api.js?onload=ReCaptchaCallback&render=explicit&hl=' + l;
        s.type = 'text/javascript';
        e.parentNode.insertBefore(s, e);
      })(document, 'g-recaptcha-script', this.lang);

      return this;
    },

    /**
     * Callback
     * @returns {Quince.ReCaptchaSrv}
     */
    callback: function () {
      var self = this;
      $('.g-recaptcha').each(function (index, el) {
        self.captchas.push(grecaptcha.render(el, {sitekey: self.publicKey}));
      });

      return this;
    },

    /**
     * Reload
     * @returns {Quince.ReCaptchaSrv}
     */
    reset: function () {
      var self = this;
      if (self.captchas.length) {
        $.each(self.captchas, function (index, captcha) {
          grecaptcha.reset(captcha);
        });
      } else {
        this.callback();
      }

      return this;
    }
  };

  /**
   * Constructor
   */
  Quince.ReCaptcha = function (params) {
    params = params || {};
  };

  /**
   * Methods
   */
  Quince.ReCaptcha.prototype = {};
  Quince.ReCaptcha.prototype.constructor = Quince.ReCaptcha;
})(jQuery, window);


Quince = Quince || {};

/**
 * GallerySlider
 */
(function ($, window) {
  'use strict';

  /**
   * Slider service
   * @type {Object}
   */
  Quince.SearchFormService = {

    doSearch: function (container) {
      var $container = $(container);
      var $input = $('input[name=q]', $container);
      if ($container.hasClass('active')) {
        if ($input.val()) {
          window.document.location.href = '/search/?q=' + encodeURIComponent($input.val()).replace(/%20/g,'+');
          return false;
        }
        $input.focus();
        return false;
      }
      $container.toggleClass('active');
      $input.focus();
      return false;
    }
  };

  /**
   * Constructor
   */
  Quince.SearchForm = function (params) {

  };

  /**
   * Methods
   */
  Quince.SearchForm.prototype = {};

  Quince.SearchForm.prototype.constructor = Quince.SearchForm;
})(jQuery, window);


Quince = Quince || {};

/**
 * VideoBgApp
 */
(function ($, window) {
    'use strict';

    /**
     * Slider service
     * @type {Object}
     */
    Quince.VideoBgAppService = {};

    /**
     * Constructor
     */
    Quince.VideoBgApp = function () {
        this.init();
    };

    /**
     * Methods
     */
    Quince.VideoBgApp.prototype = {
        /**
         * Init
         * @returns {Quince.VideoBgApp}
         */
        init: function () {
            var $videoBgs = $(".video-bg");
            if ($videoBgs.length < 1) {
                return;
            }
            var isPlayVideo = (function () {
                var isMobile = $('html').hasClass('mobile');
                var v = document.createElement('video');
                var canMP4 = v.canPlayType ? v.canPlayType('video/mp4') : false;
                return canMP4 && !isMobile;
            })();
            if (!isPlayVideo) {
                $videoBgs.each(function () {
                    var $videoBg = $(this);
                    var alt = $videoBg.data('alternative');
                    if (alt) {
                        var $img = $('<img alt class="bg" src="' + alt + '"/>');
                        $videoBg.after($img).remove();
                    }
                });
                return;
            }
            $videoBgs.each(function () {
                var $divBg = $(this);
                var loading = function (done) {
                    var $videoBg = $('<video class="video-bg"></video>');
                    if ($divBg.data('mute') === 'yes') $videoBg[0].muted = true;
                    var vol = $divBg.data('volume');
                    if (vol !== undefined) $videoBg[0].volume = vol / 100;
                    var doDone = function () {
                        var vw = $videoBg.width();
                        var vh = $videoBg.height();
                        var vr = vw / vh;
                        var $window = $(window);
                        var resize = function () {
                            var ww = $window.width();
                            var wh = $window.height();
                            var wr = ww / wh;
                            var w, h;
                            if (vr > wr) {
                                h = Math.ceil(wh);
                                w = Math.ceil(h * vr);
                            } else {
                                w = Math.ceil(ww);
                                h = Math.ceil(w / vr);
                            }
                            $videoBg.css({
                                width: w + 'px',
                                height: h + 'px',
                                top: Math.round((wh - h) / 2) + 'px',
                                left: Math.round((ww - w) / 2) + 'px'
                            });
                        };
                        $window.resize(resize);
                        resize();
                        $videoBg[0].play();
                        done();
                    };
                    $videoBg.on('ended', function () {
                        this.currentTime = 0;
                        this.play();
                        if (this.ended) {
                            this.load();
                        }
                    });
                    var isNotDone = true;
                    $videoBg.on('canplaythrough', function () {
                        if (isNotDone) {
                            isNotDone = false;
                            doDone();
                        } else {
                            this.play();
                        }
                    });
                    $videoBg[0].src = $divBg.data('video');
                    $videoBg[0].preload = "auto";
                    $divBg.after($videoBg);
                    $divBg.remove();
                };

                loading(function(){

                });

                //$divBg.data('loading', loading);
            });
        }
    };

    Quince.VideoBgApp.prototype.constructor = Quince.VideoBgApp;
})(jQuery, window);
